<template>
  <div>
    <v-card data-testid="container-my-company">
      <v-toolbar flat>
        <v-toolbar-title>My company</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text class="pb-0">
        <v-form
          data-vv-scope="form-company-profile"
          data-testid="form-company-profile"
        >
          <v-row>
            <v-col
              cols="12"
              md="4"
              lg="3"
              v-if="!$can('update', 'Superadmin')"
              data-testid="block-simple-user"
            >
              <v-list dense>
                <v-list-item
                  two-line
                  v-if="companyInfo.admin.phone"
                  data-testid="admin-phone-text"
                >
                  <v-list-item-content>
                    <v-list-item-title>Admin phone number</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ companyInfo.admin.phone }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  two-line
                  v-if="companyInfo.admin.email"
                  data-testid="admin-email-text"
                >
                  <v-list-item-content>
                    <v-list-item-title>Admin email</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ companyInfo.admin.email }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  two-line
                  v-if="companyInfo.technical.phone"
                  data-testid="technical-phone-text"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      Tech support phone number
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ companyInfo.technical.phone }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  two-line
                  v-if="companyInfo.technical.email"
                  data-testid="technical-email-text"
                >
                  <v-list-item-content>
                    <v-list-item-title>Tech support email</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ companyInfo.technical.email }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col
              cols="12"
              :md="$can('update', 'Superadmin') ? '12' : '8'"
              :lg="$can('update', 'Superadmin') ? '12' : '9'"
            >
              <v-row>
                <v-col cols="12" :md="colMdComputed" :lg="colLgComputed">
                  <v-text-field
                    outlined
                    label="Name"
                    v-model="companyInfo.name"
                    :error-messages="
                      errors.collect('form-company-profile.name')
                    "
                    v-validate="'required'"
                    data-vv-name="name"
                    data-vv-validate-on="change"
                    v-on:change="cancelSubmitDisabling"
                    :disabled="!companyUpdateComputed"
                    data-testid="name-text"
                  />
                  <v-select
                    outlined
                    v-model="companyInfo.signal_server_key"
                    :items="signalServers"
                    item-text="data.name"
                    item-value="_key"
                    :menu-props="{ maxHeight: '400' }"
                    label="Signal server"
                    :error-messages="
                      errors.collect(
                        'form-company-profile.active_signal_server'
                      )
                    "
                    data-vv-name="active_signal_server"
                    data-vv-validate-on="change"
                    v-on:change="cancelSubmitDisabling"
                    :disabled="!$can('update', 'Superadmin')"
                    data-testid="name-select"
                  />
                </v-col>

                <v-col
                  cols="12"
                  :md="colMdComputed"
                  :lg="colLgComputed"
                  v-if="$can('update', 'Superadmin')"
                >
                  <v-text-field
                    outlined
                    label="Admin phone number"
                    v-model="companyInfo.admin.phone"
                    :error-messages="
                      errors.collect('form-company-profile.admin_phone')
                    "
                    v-validate="{
                      regex:
                        '^\\s*(?:\\+?(\\d{1,3}))?[-. (]*(\\d{3})[-. )]*(\\d{3})[-. ]*(\\d{4})(?: *x(\\d+))?\\s*$',
                    }"
                    data-vv-name="admin_phone"
                    data-vv-validate-on="change"
                    v-on:change="cancelSubmitDisabling"
                    :disabled="!companyUpdateComputed"
                    data-testid="admin-phone"
                  />
                  <v-text-field
                    outlined
                    label="Admin email"
                    v-model.trim="companyInfo.admin.email"
                    :error-messages="
                      errors.collect('form-company-profile.admin_email')
                    "
                    v-validate="'email'"
                    data-vv-name="admin_email"
                    data-vv-validate-on="change"
                    v-on:change="cancelSubmitDisabling"
                    :disabled="!companyUpdateComputed"
                    data-testid="admin-email"
                  />
                </v-col>
                <v-col
                  cols="12"
                  :md="colMdComputed"
                  :lg="colLgComputed"
                  v-if="$can('update', 'Superadmin')"
                >
                  <v-text-field
                    outlined
                    label="Tech support phone number"
                    v-model="companyInfo.technical.phone"
                    :error-messages="
                      errors.collect('form-company-profile.technical_phone')
                    "
                    v-validate="{
                      regex:
                        '^\\s*(?:\\+?(\\d{1,3}))?[-. (]*(\\d{3})[-. )]*(\\d{3})[-. ]*(\\d{4})(?: *x(\\d+))?\\s*$',
                    }"
                    data-vv-name="technical_phone"
                    data-vv-validate-on="change"
                    v-on:change="cancelSubmitDisabling"
                    :disabled="!companyUpdateComputed"
                    data-testid="technical-phone"
                  />
                  <v-text-field
                    outlined
                    label="Tech support email"
                    v-model.trim="companyInfo.technical.email"
                    :error-messages="
                      errors.collect('form-company-profile.technical_email')
                    "
                    v-validate="'email'"
                    data-vv-name="technical_email"
                    data-vv-validate-on="change"
                    v-on:change="cancelSubmitDisabling"
                    :disabled="!companyUpdateComputed"
                    data-testid="technical-email"
                  />
                </v-col>
                <v-col cols="12" :md="colMdComputed" :lg="colLgComputed">
                  <v-text-field
                    outlined
                    label="Address"
                    v-model="companyInfo.address"
                    :error-messages="
                      errors.collect('form-company-profile.address')
                    "
                    v-validate=""
                    data-vv-name="address"
                    data-vv-validate-on="change"
                    v-on:change="cancelSubmitDisabling"
                    :disabled="!companyUpdateComputed"
                    data-testid="address"
                  />
                  <v-text-field
                    outlined
                    label="Website"
                    v-model="companyInfo.website"
                    :error-messages="
                      errors.collect('form-company-profile.website')
                    "
                    v-validate="{
                      regex:
                        '^((https?|ftp|smtp):\\/\\/)?(www.)?[a-z0-9]+\\.[a-z]+(\\/[a-zA-Z0-9#]+\\/?)*$',
                    }"
                    data-vv-name="website"
                    data-vv-validate-on="change"
                    v-on:change="cancelSubmitDisabling"
                    :disabled="!companyUpdateComputed"
                    data-testid="website"
                  />
                </v-col>
                <v-col cols="12" md="12" lg="6">
                  <v-textarea
                    outlined
                    label="Description"
                    v-model="companyInfo.description"
                    v-on:change="cancelSubmitDisabling"
                    :disabled="!companyUpdateComputed"
                    data-testid="description"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="companyUpdateComputed"
          color="success"
          :block="$vuetify.breakpoint.smAndDown"
          :disabled="!(errors.items.length === 0 && formHasChanged)"
          @click.stop="save()"
          data-testid="btn-save-company-profile"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { unionBy } from "lodash"
import GeneralMixin from "@/mixins/general.js"

export default {
  mixins: [GeneralMixin],

  data: () => ({
    formHasChanged: false,
  }),

  created() {
    this.getSignalServersList()
    this.getCompanyInfo()
  },

  mounted() {
    if (!this.$can("read", "groups")) {
      if (!this.$can("read", "Superadmin"))
        this.$router.push({ name: "home" }).catch((err) => {})
    }
  },

  methods: {
    getSignalServersList() {
      let getParams = {
        params: {
          type: "signal_server",
        },
      }
      this.$store.dispatch("signalServersList", getParams)
    },

    getCompanyInfo() {
      this.$store.dispatch("currentUserCompanyInfo")
    },

    save() {
      let self = this
      if (self.$can("update", "groups") || self.$can("update", "Superadmin")) {
        self.$validator.validateAll("form-company-profile").then((result) => {
          if (result) {
            let formData = {
              slug: self.currUser.group_key || "0",
              data: {
                name: self.companyInfo.name,
                signal_server_key: self.companyInfo.signal_server_key,
                admin: self.companyInfo.admin,
                technical: self.companyInfo.technical,
                address: self.companyInfo.address,
                website: self.companyInfo.website,
                description: self.companyInfo.description,
              },
            }
            console.log("heeeee")
            self.$store
              .dispatch("companyProfileUpdate", formData)
              .then(function (response) {
                if (response.data.status === "success") {
                  let params = {
                    text: "Company profile was edited successfully.",
                    show: true,
                  }
                  self.$root.$emit("snackbar", params)
                }
                self.getCompanyInfo()
              })
              .catch((error) => {
                console.log("COMPANY_INFO_UPDATE_ACTION error", error)

                let params = {
                  text: `Company profile was not edited. ${error}`,
                  show: true,
                }
                self.$root.$emit("snackbar", params)
                self.getCompanyInfo()
              })
          }
        })
      }
    },
    cancelSubmitDisabling() {
      this.formHasChanged = true
    },
  },

  computed: {
    ...mapGetters({
      currUser: "currentUser",
      companyInfo: "getCurrentUserCompanyInfo",
      signalServersList: "signalServersList",
    }),
    companyUpdateComputed() {
      if (this.$can("update", "groups") || this.$can("update", "Superadmin")) {
        return true
      }
      return false
    },
    colMdComputed() {
      if (!this.$can("update", "Superadmin")) {
        return "6"
      }
      return "3"
    },
    colLgComputed() {
      if (!this.$can("update", "Superadmin")) {
        return "4"
      }
      return "3"
    },
    signalServers() {
      return unionBy(
        this.signalServersList,
        [{ _key: null, data: { name: "Not selected" } }],
        "_key"
      )
    },
  },

  watch: {
    "companyInfo.name": function (newVal, oldVal) {
      if (oldVal !== "") {
        if (newVal !== oldVal) {
          this.formHasChanged = true
        }
      }
    },
    "companyInfo.admin.email": function (newVal, oldVal) {
      if (oldVal !== "") {
        if (newVal !== oldVal) {
          this.formHasChanged = true
        }
      }
    },
    "companyInfo.admin.phone": function (newVal, oldVal) {
      if (oldVal !== "") {
        if (newVal !== oldVal) {
          this.formHasChanged = true
        }
      }
    },
    "companyInfo.technical.email": function (newVal, oldVal) {
      if (oldVal !== "") {
        if (newVal !== oldVal) {
          this.formHasChanged = true
        }
      }
    },
    "companyInfo.technical.phone": function (newVal, oldVal) {
      if (oldVal !== "") {
        if (newVal !== oldVal) {
          this.formHasChanged = true
        }
      }
    },
    "companyInfo.address": function (newVal, oldVal) {
      if (oldVal !== "") {
        if (newVal !== oldVal) {
          this.formHasChanged = true
        }
      }
    },
    "companyInfo.website": function (newVal, oldVal) {
      if (oldVal !== "") {
        if (newVal !== oldVal) {
          this.formHasChanged = true
        }
      }
    },
    "companyInfo.description": function (newVal, oldVal) {
      if (oldVal !== "") {
        if (newVal !== oldVal) {
          this.formHasChanged = true
        }
      }
    },
  },
}
</script>

<style>
/**
  Remove space in dropdown
   */
#form-company-info .theme--dark.v-input:not(.v-input--is-disabled) input,
#form-company-info .theme--dark.v-input:not(.v-input--is-disabled) textarea {
  display: none !important;
}

#form-company-info .v-select__selection {
  max-width: 100% !important;
}
</style>
